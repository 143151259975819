import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {VendorIdValid} from "./vendor-id-valid";
import {VatIdValid} from "./vatid-valid";
import {RemainBudgetValid} from "./remain-budget-valid";
import { OriginDirectorValidDirective } from './origin-director-valid.directive';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    VendorIdValid,
    VatIdValid,
    RemainBudgetValid,
    OriginDirectorValidDirective
  ],
  exports: [
    VendorIdValid,
    VatIdValid,
    RemainBudgetValid,
    OriginDirectorValidDirective
  ],
  providers: []
})
export class SharedValidatorsModule {
}
