import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "./pages/layout/layout.component";
import {FrontChannelLogoutComponent} from "./front-channel-logout/front-channel-logout.component";
import {LandingComponent} from "./pages/landing/landing.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {P403Component} from "./shared/commons/interceptor/error/403.component";
import {FileUploadPatcherComponent} from "./pages/file-upload-patcher/file-upload-patcher.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: "full",
    redirectTo: "landing"
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'landing',
        component: LandingComponent
      },
      {
        path: 'contract',
        loadChildren: () => import("./pages/contract/contract.module").then(value => value.ContractModule)
      },
      {
        path: 'master-data',
        loadChildren: () => import("./pages/master-data/master-data.module").then(value => value.MasterDataModule)
      },
      {
        path: 'reports',
        loadChildren: () => import("./pages/reports/reports.module").then(value => value.ReportsModule)
      },
      {
        path: '403',
        component: P403Component,
      },
    ]
  },
  {
    path: 'logout',
    component: FrontChannelLogoutComponent
  },
  {
    path: 'file-upload-patcher',
    component: FileUploadPatcherComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: "eager",
    paramsInheritanceStrategy: "always",
    anchorScrolling: "enabled",
    scrollOffset: [0, 80]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
