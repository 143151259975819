import {Component, HostListener, inject, isDevMode, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NgbOffcanvas, OffcanvasDismissReasons} from "@ng-bootstrap/ng-bootstrap";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "../../../environments/environment";
import {Version, VersionService} from "../../version.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  public user: any;
  toggleOffCanvas = false;
  sidebarCollapsed = false;
  closeResult = '';
  version: Version;
  renderer2 = inject(Renderer2);

  constructor(private offcanvasService: NgbOffcanvas,
              private oidcSecurityService: OidcSecurityService,
              private versionService: VersionService) {
  }

  ngOnInit() {
    this.calculateMainContentTop();
    this.oidcSecurityService.userData$
      .subscribe(userData => {
        this.user = userData.userData;
      });
    this.versionService.getVertion().subscribe(e => this.version = e);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // 992px is break point of large devices
    this.toggleOffCanvas = event.target.innerWidth < 992;
    this.sidebarCollapsed = event.target.innerWidth < 992;
    // this.calculateMainContentTop();
  }

  userInfo() {
    window.open(`${environment.oidcIssuerUrl}/account`, '_blank');
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(e => {
      console.log(e)
    });
  }

  open(content) {
    this.offcanvasService.open(content, {ariaLabelledBy: 'offcanvas-basic-title'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === OffcanvasDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on the backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  gotoEportal() {
    window.location.href = `${environment.portalUrl}/#/portal`;
  }

  ngOnDestroy(): void {
  }

  private calculateMainContentTop() {
    let mainContainer = document.getElementById("main-container");
    let topNav = document.getElementById("top-nav");
    let topNav2 = document.getElementById("top-nav2");
    if (mainContainer) {
      mainContainer.style.top = ((topNav?.clientHeight || 0) + (topNav2?.clientHeight || 0)) + "px";
      mainContainer.style.position = 'absolute';
    }
  }

  setThemeToDefault() {
    let body = document.body;
    this.renderer2.removeClass(body, 'custom-theme2');
    document.documentElement.style.setProperty('--default-primary-color', '#a020f0')
    document.documentElement.style.setProperty('--bs-btn-bg', '#a020f0')
  }

  setThemeToCustom2() {
    let body = document.body;
    this.renderer2.addClass(body, 'custom-theme2');
    document.documentElement.style.setProperty('--default-primary-color', 'blue')
    document.documentElement.style.setProperty('--bs-btn-bg', 'blue')

  }

  protected readonly isDevMode = isDevMode;
}
