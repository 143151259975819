import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AlertDialogService} from "../dialog/alert-dialog/alert-dialog.service";

export const IGNORE_417_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Http417Interceptor implements HttpInterceptor {

  constructor(private alertDialogService: AlertDialogService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return req.context.get(IGNORE_417_ERROR) ? next.handle(req) : next.handle(req)
      .pipe(tap
        (event => {
          },
          (err: HttpErrorResponse) => {
            if (err.status == 417) {
              console.warn(err)
              this.alertDialogService.open({
                title: 'กรุณาตรวจสอบข้อมูล',
                type: 'warning',
                isInnerHTML: true,
                content: `<div class="text-center">${err.error?.message}</div>`
              });
            }
          })
      );
  }


}
